import script from "./help-dropdown.vue?vue&type=script&setup=true&lang=js"
export * from "./help-dropdown.vue?vue&type=script&setup=true&lang=js"
/* custom blocks */
import block0 from "./help-dropdown.vue?vue&type=custom&index=0&blockType=i18n&lang=json5"
if (typeof block0 === 'function') block0(script)
import block1 from "./help-dropdown.vue?vue&type=custom&index=1&blockType=i18n"
if (typeof block1 === 'function') block1(script)


const __exports__ = script;

export default __exports__